import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';

class RedirectToRoot extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    localStorage.setItem(
      'hub-redirect-route',
      `${location.pathname}${location.search}`
    );
  }

  render() {
    const path =
      localStorage.getItem('hub-icc-only') === '1' ? '/icc/login' : '/';
    return <Redirect to={path} />;
  }
}

export default withRouter(RedirectToRoot);
