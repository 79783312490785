import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';

import AuthBox from './auth/AuthBox';
import ManualLink from './ManualLink';

import {
  AppBar,
  Grid,
  Hidden,
  IconButton,
  Toolbar,
  withStyles
} from '@material-ui/core';

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = theme => ({
  menuButton: {
    marginLeft: -theme.spacing(1)
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white
    }
  },
  button: {
    borderColor: lightColor
  },
  left: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  }
});

const AppHeader = props => {
  const {
    classes,
    disablePermanent = false,
    disableDrawer = false,
    left,
    onDrawerToggle,
    color = 'primary',
    className = '',
    position = 'sticky'
  } = props;

  return (
    <AppBar
      color={color}
      position={position}
      elevation={0}
      className={className}
    >
      <Toolbar>
        <Grid container spacing={2} alignItems="center">
          {!disableDrawer && (
            <Hidden mdUp={!disablePermanent}>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
          )}
          <div className={classes.left}>{left}</div>
          <Grid item xs />
          <Grid item>
            <ManualLink />
          </Grid>
          <Grid item>
            <AuthBox />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(AppHeader);
