import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary.main
  }
});

const LoadingScreen = props => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <ReactLoading type="cubes" height={'110px'} width={'110px'} />
    </div>
  );
};

LoadingScreen.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LoadingScreen);
