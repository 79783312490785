import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import LazyRoute from 'components/LazyRoute';
import { withAuth } from 'components/auth/AuthProvider';

const AppsRoutes = ({ project, match, accessControl }) => {
  const { authorizedApps } = accessControl;

  return (
    <Switch>
      {authorizedApps &&
        authorizedApps.map(app => (
          <LazyRoute
            key={app}
            path={`${match.path}/${app}`}
            page={`project/apps/${app}/routes`}
          />
        ))}
      <Redirect to={`/p/${project.id}/overview`} />
    </Switch>
  );
};

export default withAuth(AppsRoutes);
