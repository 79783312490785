import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';

import FirestoreDocument from 'modules/react-firestore/FirestoreDocument';
import LoadingScreen from 'components/LoadingScreen';
import { AuthConsumer } from 'components/auth/AuthProvider';

class WrapperComponent extends Component {
  state = {
    done: false
  };

  componentDidMount() {
    const { organizationId, handleOrganizationChange } = this.props;

    handleOrganizationChange(organizationId, () => {
      this.setState({ done: true });
    });
  }

  render() {
    return !this.state.done ? null : this.props.children;
  }
}

const OrganizationProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    render={props => {
      const {
        match: {
          params: { organizationId }
        }
      } = props;

      return (
        <FirestoreDocument
          path={`/organizations/${organizationId}`}
          render={({ isLoading, data: organization }) =>
            isLoading ? (
              <LoadingScreen />
            ) : organization && !organization.archived ? (
              <AuthConsumer>
                {({ handleOrganizationChange, ...rest }) => (
                  <WrapperComponent
                    organizationId={organizationId}
                    handleOrganizationChange={handleOrganizationChange}
                  >
                    <Component organization={organization} {...props} />
                  </WrapperComponent>
                )}
              </AuthConsumer>
            ) : (
              <Redirect to="/o" />
            )
          }
        />
      );
    }}
    {...rest}
  />
);

export default OrganizationProtectedRoute;
