import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { FirestoreDocument } from 'react-firestore';

import LoadingScreen from 'components/LoadingScreen';
import { AuthConsumer } from 'components/auth/AuthProvider';

class WrapperComponent extends Component {
  state = {
    done: false
  };

  componentDidMount() {
    const { project, handleProjectChange } = this.props;
    handleProjectChange(project, () => {
      this.setState({ done: true });
    });
  }

  render() {
    return !this.state.done ? null : this.props.children;
  }
}

const ProjectProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    render={props => {
      const {
        match: {
          params: { projectId }
        }
      } = props;
      return (
        <FirestoreDocument
          path={`/projects/${projectId}`}
          render={({ isLoading, data: project }) =>
            isLoading ? (
              <LoadingScreen />
            ) : project && !project.archived ? (
              <AuthConsumer>
                {({ handleProjectChange, ...rest }) => (
                  <WrapperComponent
                    project={project}
                    handleProjectChange={handleProjectChange}
                  >
                    <Component project={project} {...props} />
                  </WrapperComponent>
                )}
              </AuthConsumer>
            ) : (
              <Redirect to="/p" />
            )
          }
        />
      );
    }}
    {...rest}
  />
);

export default ProjectProtectedRoute;
