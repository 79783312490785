import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { FirestoreProvider } from 'react-firestore';

import { firebase } from './modules/firebase-helper';
import AuthProvider from 'components/auth/AuthProvider';
import ThemeProvider from 'components/ThemeProvider';
import AppRoutes from 'pages/AppRoutes';

import './App.css';

const App = props => (
  <Router>
    <AuthProvider>
      <FirestoreProvider firebase={firebase} useTimestampsInSnapshots={true}>
        <ThemeProvider>
          <AppRoutes />
        </ThemeProvider>
      </FirestoreProvider>
    </AuthProvider>
  </Router>
);

export default App;
