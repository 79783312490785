export const firebase = {
  apiKey: process.env.REACT_APP_FIREBASE_apiKey,
  authDomain: process.env.REACT_APP_FIREBASE_authDomain,
  databaseURL: process.env.REACT_APP_FIREBASE_databaseURL,
  projectId: process.env.REACT_APP_FIREBASE_projectId,
  storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
  messagingSenderId: process.env.REACT_APP_FIREBASE_apiKey
};

export const googleAuth = {
  clientId: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID
};

export const fastly = {
  domain: process.env.REACT_APP_FASTLY_DOMAIN
};

export const algolia = {
  appId: process.env.REACT_APP_ALGOLIA_APP_ID
};

export const sentry = {
  dsn: process.env.REACT_APP_SENTRY_DSN
};
