const iccManual = 'https://icc-manual-dot-fm-developer.appspot.com/';
const tagservicesManual = 'https://hub-docs-dot-fm-bird-practice.appspot.com/';
export const hubManual = 'https://hub-docs-dot-fm-bird-practice.appspot.com/';

export const apps = [
  {
    value: 'tagservices',
    label: 'Tag Services',
    manualLink: tagservicesManual
  },
  {
    value: 'icc',
    label: 'Inventory Control Center',
    manualLink: iccManual
  }
];

export const translateAppValueToOptions = values =>
  apps.filter(option => values.indexOf(option.value) !== -1);
