import React, { Component, createContext } from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ExtensionIcon from '@material-ui/icons/Extension';
import AppsIcon from '@material-ui/icons/Apps';
import { withAuth } from 'components/auth/AuthProvider';

import AppFrame from 'components/AppFrame';
import { Typography } from '@material-ui/core';

const { Provider, Consumer: ProjectFrameConsumer } = createContext({});

const AppIcons = {
  tagservices: <ExtensionIcon />,
  default: <AppsIcon />
};

const AppNames = {
  tagservices: 'Tag Services',
  icc: 'ICC'
};

class ProjectFrame extends Component {
  state = {
    drawer: null,
    disablePermanent: false
  };

  componentDidMount() {
    const {
      project: { id: projectId },
      accessControl
    } = this.props;

    const { authorizedApps } = accessControl;

    const settingRoutes = {
      name: 'Settings',
      children: [
        {
          name: 'Members',
          icon: <PeopleIcon />,
          path: `/p/${projectId}/members`
        }
      ]
    };

    if (authorizedApps) {
      const appRoutes = {
        name: 'Apps',
        children: authorizedApps.map(app => ({
          name: AppNames[app] || app,
          icon: AppIcons[app] || AppIcons['default'],
          path: `/p/${projectId}/apps/${app}`
        }))
      };

      this.setState({
        drawer: [appRoutes, settingRoutes]
      });
    } else {
      this.setState({
        drawer: [settingRoutes]
      });
    }
  }

  setDisablePermanent(bool) {
    if (this.state.disablePermanent !== bool) {
      this.setState({
        disablePermanent: bool
      });
    }
  }

  render() {
    const {
      project: { id: projectId, name: projectName },
      accessControl: { isOnlyIccUser = false }
    } = this.props;

    const overview = {
      name: 'Overview',
      icon: <DashboardIcon />,
      path: `/p/${projectId}/overview`
    };

    const left = (
      <Typography
        display="block"
        component="p"
        color="inherit"
        style={{ marginLeft: 18 }}
      >
        Project: {projectName}
      </Typography>
    );

    return (
      <AppFrame
        title="HUB"
        overview={overview}
        left={left}
        disableDrawer={isOnlyIccUser}
        {...this.state}
      >
        <Provider
          value={{
            setDisablePermanent: this.setDisablePermanent.bind(this)
          }}
        >
          {this.props.children}
        </Provider>
      </AppFrame>
    );
  }
}

export default withAuth(ProjectFrame);

export { ProjectFrameConsumer };
