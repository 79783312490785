const hasAnyRoles = (userRoles, roles) => {
  if (!userRoles || userRoles.length === 0) {
    return false;
  }

  for (let i = 0; i < userRoles.length; i++) {
    if (roles.indexOf(userRoles[i]) !== -1) return true;
  }

  return false;
};

const hasOnlyRoles = (userRoles, roles) => {
  if (!userRoles || userRoles.length === 0) {
    return false;
  }

  for (let i = 0; i < userRoles.length; i++) {
    if (roles.indexOf(userRoles[i]) === -1) return false;
  }

  return true;
};

const organizationLevelMap = {
  admin: 10,
  editor: 5,
  viewer: 0
};

const organizationRoleTransferMap = {
  admin: 'admin',
  editor: 'editor',
  viewer: 'viewer'
};

const projectLevelMap = {
  admin: 10,
  editor: 5,
  viewer: 0
};

const appsLevelMap = {
  icc: {
    admin: 10,
    editor: 0,
    viewer: 0,
    'icc.editor': 5,
    'icc.viewer': 0
  },
  tagservices: {
    admin: 10,
    editor: 5,
    viewer: 0,
    'tagservices.admin': 10,
    'tagservices.editor': 5,
    'tagservices.viewer': 0
  }
};

export const createAccessControl = (
  currentUser,
  organizationId,
  projectId,
  project = {}
) => {
  const { projects = {}, organizations = {} } = currentUser;

  const projectRoles =
    projects && projects[projectId] ? projects[projectId] : [];

  const organizationRoles =
    organizations && organizations[organizationId]
      ? organizations[organizationId]
      : [];

  let organizationLevel = -1;
  let projectLevel = -1;
  const appsLevel = {};
  const authorizedApps = [];

  if (organizationId) {
    for (let role of organizationRoles) {
      const _organizationLevel = organizationLevelMap.hasOwnProperty(role)
        ? organizationLevelMap[role]
        : -1;
      organizationLevel = Math.max(organizationLevel, _organizationLevel);
    }
  }

  if (projectId) {
    for (let role of organizationRoles) {
      const _role = organizationRoleTransferMap[role];
      if (_role) {
        projectRoles.push(_role);
      }
    }

    for (let role of projectRoles) {
      const _projectLevel = projectLevelMap.hasOwnProperty(role)
        ? projectLevelMap[role]
        : -1;
      projectLevel = Math.max(projectLevel, _projectLevel);
    }

    if (project.apps) {
      for (let app of project.apps) {
        const m = appsLevelMap[app];
        if (!app) {
          continue;
        }

        let level = -1;
        for (let role of projectRoles) {
          let _level = m.hasOwnProperty(role) ? m[role] : -1;
          level = Math.max(level, _level);
        }
        appsLevel[app] = level;

        if (level >= 0) {
          authorizedApps.push(app);
        }
      }
    }
  }

  const isOrganizationMember = organizationLevel >= 0;
  const isOrganizationEditor = organizationLevel >= 5;
  const isOrganizationAdmin = organizationLevel >= 10;

  const isProjectMember = projectLevel >= 0;
  const isProjectEditor = projectLevel >= 5;
  const isProjectAdmin = projectLevel >= 10;

  const isOnlyIccUser =
    projectLevel < 0 &&
    hasOnlyRoles(projectRoles, ['icc.editor', 'icc.viewer']);

  return {
    organizationId,
    projectId,
    organization: organizationLevel,
    project: projectLevel,
    apps: appsLevel,
    authorizedApps,
    isOrganizationMember,
    isOrganizationEditor,
    isOrganizationAdmin,
    isProjectMember,
    isProjectEditor,
    isProjectAdmin,
    isOnlyIccUser
  };
};
