import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { AuthConsumer } from './AuthProvider';

import {
  withStyles,
  Chip,
  Menu,
  MenuItem,
  Button,
  Avatar,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';

const styles = theme => ({
  chip: {
    color: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.primary.contrastText}`
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    outline: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      backgroundImage: 'linear-gradient(transparent 94%,#3b78e7 94%)'
    }
  }
});

class AuthBox extends Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <AuthConsumer>
        {({ currentUser, logout }) => {
          return currentUser ? (
            <React.Fragment>
              <Chip
                className={classes.chip}
                avatar={
                  currentUser.picture && (
                    <Avatar alt="Remy Sharp" src={currentUser.picture} />
                  )
                }
                label={currentUser.displayName || currentUser.email}
                onClick={this.handleClick}
                variant="outlined"
                color="default"
              />

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={this.handleClose}
              >
                {currentUser.organizations && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={'/o'}
                    className={classes.link}
                  >
                    <MenuItem>
                      <ListItemText primary="Organizations" />
                      <ListItemIcon>
                        <OpenInNewIcon />
                      </ListItemIcon>
                    </MenuItem>
                  </a>
                )}
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </React.Fragment>
          ) : (
            <Button color="inherit">
              <NavLink
                to={`/`}
                style={{ textDecoration: 'none', color: 'unset' }}
              >
                Login
              </NavLink>
            </Button>
          );
        }}
      </AuthConsumer>
    );
  }
}

export default withStyles(styles)(AuthBox);
