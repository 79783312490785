import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import LazyRoute from 'components/LazyRoute';
import { LazyProtectedRoute } from 'components/auth/ProtectedRoute';
import ProjectProtectedRoute from 'components/project/ProjectProtectedRoute';
import ProjectFrame from 'components/project/ProjectFrame';
import { AuthConsumer } from 'components/auth/AuthProvider';
import AppsRoutes from './apps/AppsRoutes';
import LoadingScreen from 'components/LoadingScreen';

const ProjectRoutes = ({ project }) => {
  const { id: projectId } = project;
  return (
    <ProjectFrame>
      <AuthConsumer>
        {({ isLoading: authIsLoading, accessControl }) => {
          if (authIsLoading) {
            return <LoadingScreen />;
          }

          return accessControl.isOnlyIccUser ? (
            <Switch>
              <Route
                path={`/p/:projectId/apps`}
                render={props => <AppsRoutes {...props} project={project} />}
              />
              <Route
                render={props => <Redirect to={`/p/${projectId}/apps/icc`} />}
              />
            </Switch>
          ) : (
            <Switch>
              <Route
                path={`/p/:projectId/apps`}
                render={props => <AppsRoutes {...props} project={project} />}
              />
              <LazyRoute
                path={`/p/:projectId/overview`}
                page="project/Overview"
              />
              <LazyRoute
                path={`/p/:projectId/members`}
                page="project/Members"
              />
              <Route
                render={props => <Redirect to={`/p/${projectId}/overview`} />}
              />
            </Switch>
          );
        }}
      </AuthConsumer>
    </ProjectFrame>
  );
};

export default () => (
  <Switch>
    <ProjectProtectedRoute path="/p/:projectId" component={ProjectRoutes} />
    <LazyProtectedRoute exact path="/p" page="project/Index" />
  </Switch>
);
