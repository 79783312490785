import React from 'react';
import { Switch } from 'react-router-dom';

import LazyRoute from 'components/LazyRoute';
import {
  ProtectedRoute,
  LazyProtectedRoute
} from 'components/auth/ProtectedRoute';
import ProjectRoutes from './project/ProjectRoutes';
import OrganizationRoutes from './organization/OrganizationRoutes';

const AppRoutes = props => (
  <Switch>
    <LazyProtectedRoute
      exact
      path="/o/invitation"
      page="organization/Invitation"
    />
    <LazyProtectedRoute exact path="/p/invitation" page="project/Invitation" />
    <ProtectedRoute path="/p" component={ProjectRoutes} />
    <ProtectedRoute path="/o" component={OrganizationRoutes} />
    <LazyRoute exact path="/usermgmt" page="UserMgmt" />
    <LazyProtectedRoute
      exact
      path="/email-verification-sent"
      page="EmailVerificationSent"
      emailVerified={false}
    />
    <LazyRoute exact path="/forbidden" page="Forbidden" />
    <LazyRoute exact path="/" page="Index" />

    <LazyRoute exact path="/icc/login" page="project/apps/icc/Index" />
    <LazyRoute page="NotFound" />
  </Switch>
);

export default AppRoutes;
