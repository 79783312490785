import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { NavLink } from 'react-router-dom';
import {
  withStyles,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';

const styles = theme => ({
  root: {
    backgroundColor: '#18202c'
  },
  categoryHeader: {
    paddingTop: 16,
    paddingBottom: 16
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white
  },
  item: {
    paddingTop: 8,
    paddingBottom: 8,
    color: 'rgba(255, 255, 255, 0.7)'
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: 16,
    paddingBottom: 16
  },
  title: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white
  },
  itemActionable: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)'
    }
  },
  itemActiveItem: {
    color: theme.palette.primary.main
  },
  itemPrimary: {
    color: 'inherit',
    fontSize: theme.typography.fontSize,
    '&$dense': {
      fontSize: theme.typography.fontSize
    }
  },
  itemTitle: {
    padding: 0,
    margin: 0
  },
  itemText: {
    padding: '0 16px',
    margin: 0
  },
  textDense: {
    margin: 0
  },
  divider: {
    backgroundColor: '#404854',
    marginTop: theme.spacing(2)
  },

  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  noFocus: {
    '&:focus': {
      outline: 'none'
    }
  },
  icon: {
    minWidth: 0
  }
});

const noop = () => {};

const AppDrawer = props => {
  const {
    classes,
    drawer,
    overview,
    title,
    onClose = noop,
    staticContext,
    ...other
  } = props;
  const routeIsActive = path => {
    const routerPath = props.location.pathname;
    return routerPath.indexOf(`${path}/`) === 0 || routerPath === path;
  };

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: classes.root
      }}
      onClose={onClose}
      {...other}
    >
      <div
        tabIndex={0}
        role="button"
        onClick={onClose}
        onKeyDown={onClose}
        className={classes.noFocus}
      >
        <List disablePadding>
          <ListItem
            className={classNames(
              classes.title,
              classes.item,
              classes.itemCategory
            )}
          >
            {title}
          </ListItem>
          {overview && (
            <NavLink
              to={overview.path}
              className={classes.link}
              activeClassName="active"
            >
              <ListItem
                className={classNames(classes.item, classes.itemCategory)}
                button
              >
                <ListItemIcon className={classes.icon}>
                  {overview.icon}
                </ListItemIcon>
                <ListItemText
                  classes={{
                    root: classes.itemText,
                    primary: classes.itemPrimary,
                    dense: classes.textDense
                  }}
                >
                  {overview.name}
                </ListItemText>
              </ListItem>
            </NavLink>
          )}

          {drawer &&
            drawer.map(({ name, children }, i) => (
              <React.Fragment key={i}>
                <ListItem className={classes.categoryHeader}>
                  <ListItemText
                    classes={{
                      root: classes.itemTitle,
                      primary: classes.categoryHeaderPrimary,
                      dense: classes.textDense
                    }}
                  >
                    {name}
                  </ListItemText>
                </ListItem>
                {children.map(({ name: childName, path = '/', icon }, i) => (
                  <NavLink
                    to={path}
                    className={classes.link}
                    activeClassName="active"
                    key={i}
                  >
                    <ListItem
                      button
                      dense
                      className={classNames(
                        classes.item,
                        classes.itemActionable,
                        routeIsActive(path) && classes.itemActiveItem
                      )}
                    >
                      <ListItemIcon className={classes.icon}>
                        {icon}
                      </ListItemIcon>
                      <ListItemText
                        classes={{
                          root: classes.itemText,
                          primary: classes.itemPrimary,
                          dense: classes.textDense
                        }}
                      >
                        {childName}
                      </ListItemText>
                    </ListItem>
                  </NavLink>
                ))}
                <Divider className={classes.divider} />
              </React.Fragment>
            ))}
        </List>
      </div>
    </Drawer>
  );
};

export default compose(withRouter, withStyles(styles))(AppDrawer);
