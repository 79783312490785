import firebase from '@firebase/app';
import '@firebase/firestore';
import '@firebase/auth';
import '@firebase/functions';
import { firebase as firebaseConfig } from 'config';

firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
const settings = {};
firestore.settings(settings);

const auth = firebase.auth();

const firestoreServerTimestamp = () =>
  firebase.firestore.FieldValue.serverTimestamp();

export { auth, firebase, firestore, firestoreServerTimestamp };
