import React, { Component } from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FolderIcon from '@material-ui/icons/Folder';
import PeopleIcon from '@material-ui/icons/People';
import { createTheme } from 'modules/material-theme';

import AppFrame from 'components/AppFrame';
import { MuiThemeProvider } from '@material-ui/core';

const theme = createTheme('dark');

class OrganizationFrame extends Component {
  render() {
    const {
      organization: { id: organizationId }
    } = this.props;

    const overview = {
      name: 'Overview',
      icon: <DashboardIcon />,
      path: `/o/${organizationId}/overview`
    };

    const drawer = [
      {
        name: 'Settings',
        children: [
          {
            name: 'Projects',
            icon: <FolderIcon />,
            path: `/o/${organizationId}/projects`
          },
          {
            name: 'Members',
            icon: <PeopleIcon />,
            path: `/o/${organizationId}/members`
          }
        ]
      }
    ];

    return (
      <MuiThemeProvider theme={theme}>
        <AppFrame title="HUB" overview={overview} drawer={drawer}>
          {this.props.children}
        </AppFrame>
      </MuiThemeProvider>
    );
  }
}

export default OrganizationFrame;
