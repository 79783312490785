import React, { Component } from 'react';
import { withRouter, matchPath } from 'react-router-dom';
import defaultTheme, { createTheme } from 'modules/material-theme';

import { MuiThemeProvider } from '@material-ui/core';

const tagservicesTheme = createTheme('light', {
  light: '#56c8d8',
  main: '#0097a7',
  dark: '#006978'
});

const iccTheme = createTheme('light', {
  light: '#6fbf73',
  main: '#4caf50',
  dark: '#357a38',
  contrastText: '#ffffff'
});

class ThemeProvider extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const theme = this.getThemeByPath(location.pathname);
    this.state = { theme };
  }

  componentDidMount() {
    const { history } = this.props;
    this.unlisten = history.listen((location, action) => {
      const theme = this.getThemeByPath(location.pathname);
      this.setState({
        theme
      });
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  getThemeByPath(pathname) {
    if (pathname === '/icc/login') {
      return iccTheme;
    }

    const match = matchPath(pathname, {
      path: '/p/:projectId/apps/:appName'
    });
    let appName = '';
    if (match && match.params) {
      appName = match.params.appName;
    }

    switch (appName) {
      case 'tagservices':
        return tagservicesTheme;
      case 'icc':
        return iccTheme;
      default:
        return defaultTheme;
    }
  }

  render() {
    return (
      <MuiThemeProvider theme={this.state.theme}>
        {this.props.children}
      </MuiThemeProvider>
    );
  }
}

export default withRouter(ThemeProvider);
