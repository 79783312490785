import React from 'react';
import { CssBaseline, withStyles, Hidden } from '@material-ui/core';

import AppDrawer from './AppDrawer';
import AppHeader from './AppHeader';

const drawerWidth = 256;

const styles = theme => ({
  root: {
    display: 'flex',
    height: '100%'
  },
  drawer: {},
  drawerShift: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  appContentShift: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)!important`
    }
  }
});

class AppFrame extends React.Component {
  state = {
    mobileOpen: false
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  render() {
    const {
      classes,
      overview,
      drawer = [],
      disablePermanent = false,
      disableDrawer = false,
      title,
      left,
      children
    } = this.props;

    let drawerClassName = classes.drawer;
    let appContentClassName = classes.appContent;
    if (!disablePermanent) {
      drawerClassName += ` ${classes.drawerShift}`;
      appContentClassName += ` ${classes.appContentShift}`;
    }

    return (
      <div className={classes.root}>
        <CssBaseline />

        {!disableDrawer && (
          <nav className={drawerClassName}>
            <Hidden mdUp={!disablePermanent} implementation="js">
              <AppDrawer
                title={title}
                overview={overview}
                drawer={drawer}
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
              />
            </Hidden>
            {disablePermanent ? null : (
              <Hidden smDown implementation="css">
                <AppDrawer
                  title={title}
                  overview={overview}
                  drawer={drawer}
                  PaperProps={{ style: { width: drawerWidth } }}
                />
              </Hidden>
            )}
          </nav>
        )}

        <div className={appContentClassName}>
          <AppHeader
            disableDrawer={disableDrawer}
            onDrawerToggle={this.handleDrawerToggle}
            disablePermanent={disablePermanent}
            left={left}
          />
          {children}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AppFrame);
