import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import LazyRoute from 'components/LazyRoute';
import { LazyProtectedRoute } from 'components/auth/ProtectedRoute';
import OrganizationProtectedRoute from 'components/organization/OrganizationProtectedRoute';
import OrganizationFrame from 'components/organization/OrganizationFrame';

const OrganizationRoutes = ({ organization }) => {
  const { id: organizationId } = organization;
  return (
    <OrganizationFrame organization={organization}>
      <Switch>
        <LazyRoute
          path={`/o/:organizationId/overview`}
          page="organization/Overview"
        />
        <LazyRoute
          path={`/o/:organizationId/projects`}
          page="organization/Projects"
        />
        <LazyRoute
          path={`/o/:organizationId/members`}
          page="organization/Members"
        />
        <Route
          render={props => <Redirect to={`/o/${organizationId}/overview`} />}
        />
      </Switch>
    </OrganizationFrame>
  );
};

export default () => (
  <Switch>
    <OrganizationProtectedRoute
      path="/o/:organizationId"
      component={OrganizationRoutes}
    />
    <LazyProtectedRoute exact path="/o" page="organization/Index" />
  </Switch>
);
