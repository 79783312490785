import React, { Component, createContext } from 'react';

import { auth } from 'modules/firebase-helper';
import { getRoleOptions } from 'config/project-roles';
import { runFunction } from 'modules/api';
import { createAccessControl } from 'modules/access-controller';

const { Provider, Consumer: AuthConsumer } = createContext({});

class AuthProvider extends Component {
  state = {
    currentUser: null,
    isLoading: true,
    project: null,
    algoliaApiKey: null,
    accessControl: {}
  };

  componentDidMount() {
    this.refreshAuth();
  }

  refreshAuth() {
    if (this.unregisterAuthObserver) {
      this.unregisterAuthObserver();
    }

    if (this.interval) {
      clearInterval(this.interval);
    }

    this.unregisterAuthObserver = auth.onAuthStateChanged(async user => {
      if (!user) {
        this.setState({
          isLoading: false
        });
        return;
      }

      const idToken = await user.getIdTokenResult(true);
      this.setState(
        {
          currentUser: {
            ...idToken.claims,
            displayName: user.displayName
          },
          isLoading: false
        },
        this.getAlgoliaApiKey
      );
    });

    // Refresh Algolia token every 20 min
    this.interval = setInterval(this.getAlgoliaApiKey.bind(this), 1200000);
  }

  async sendVerificationEmail(force = false) {
    const { currentUser } = this.state;

    if (currentUser && currentUser.email_verified) return;
    if (localStorage.getItem('email-verification-sent') === '1' && !force) {
      return;
    }

    const user = auth.currentUser;
    await user.sendEmailVerification().catch(err => console.error(err));

    localStorage.setItem('email-verification-sent', '1');
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
    clearInterval(this.interval);
  }

  getAlgoliaApiKey() {
    if (this.state.currentUser && this.state.currentUser.email_verified) {
      runFunction('authGetAlgoliaToken')
        .then(data =>
          this.setState({
            algoliaApiKey: data['public_key']
          })
        )
        .catch(err =>
          this.setState({
            algoliaApiKey: null
          })
        );
    }
  }

  destroySession() {
    this.setState({
      currentUser: null
    });
  }

  logout() {
    return auth
      .signOut()
      .then(this.destroySession.bind(this))
      .catch(error => {
        console.log(error);
      });
  }

  handleProjectChange = (project, callback) => {
    const { organization: organizationId } = project;
    const accessControl = createAccessControl(
      this.state.currentUser,
      organizationId,
      project.id,
      project
    );
    this.setState({ project, accessControl }, () => {
      if (callback) callback();
    });

    localStorage.setItem(
      'hub-icc-only',
      !accessControl.isOnlyIccUser ? '0' : '1'
    );
  };

  handleOrganizationChange = (organizationId, callback) => {
    const accessControl = createAccessControl(
      this.state.currentUser,
      organizationId
    );
    this.setState({ accessControl }, () => {
      if (callback) callback();
    });
  };

  isParentMember() {
    const {
      currentUser: { organizations },
      project: { organization: organizationId }
    } = this.state.currentUser;
    return organizations && organizations[organizationId].length > 0;
  }

  getProjectRoleOptions = isAdmin => {
    return getRoleOptions(this.state.project.apps || [], isAdmin);
  };

  render() {
    const projectIds = [];
    if (this.state.currentUser && this.state.currentUser.projects) {
      Object.keys(this.state.currentUser.projects).forEach(projectId => {
        if (this.state.currentUser.projects[projectId].length > 0) {
          projectIds.push(projectId);
        }
      });
    }

    const organizationIds = [];
    if (this.state.currentUser && this.state.currentUser.organizations) {
      Object.keys(this.state.currentUser.organizations).forEach(
        organizationId => {
          if (this.state.currentUser.organizations[organizationId].length > 0) {
            organizationIds.push(organizationId);
          }
        }
      );
    }

    return (
      <Provider
        value={{
          ...this.state,
          organizationIds,
          projectIds,
          logout: this.logout.bind(this),
          refreshAuth: this.refreshAuth.bind(this),
          sendVerificationEmail: this.sendVerificationEmail.bind(this),
          handleProjectChange: this.handleProjectChange.bind(this),
          isParentMember: this.isParentMember.bind(this),
          getProjectRoleOptions: this.getProjectRoleOptions.bind(this),
          handleOrganizationChange: this.handleOrganizationChange.bind(this)
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

const withAuth = WrappedComponent => props => (
  <AuthConsumer>
    {({ isLoading, ...rest }) =>
      isLoading ? <div /> : <WrappedComponent {...props} {...rest} />
    }
  </AuthConsumer>
);

export default AuthProvider;

export { AuthConsumer, withAuth };
