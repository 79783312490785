export const projectRoles = [
  { value: 'admin', label: 'Administrator' },
  { value: 'editor', label: 'Editor' },
  { value: 'viewer', label: 'Viewer' }
];

const tagservicesRoles = [
  { value: 'tagservices.admin', label: 'Tag Services - Administrator' },
  { value: 'tagservices.editor', label: 'Tag Services - Editor' }
];

const iccRoles = [
  // { value: 'icc.admin', label: 'Inventory Control Center - Administrator' },
  { value: 'icc.editor', label: 'Inventory Control Center - Editor' },
  { value: 'icc.viewer', label: 'Inventory Control Center - Viewer' }
];

const _allRoles = [...projectRoles, ...tagservicesRoles, ...iccRoles];

export const rolesMap = {
  project: {
    label: 'Project',
    options: projectRoles
  },
  tagservices: {
    label: 'Tag Services',
    options: tagservicesRoles
  },
  icc: {
    label: 'Inventory Control Center',
    options: iccRoles
  }
};

export const getRoleOptions = (apps, isAdmin = true) => {
  if (!isAdmin) {
    return ['project', ...apps]
      .map(app => rolesMap[app])
      .map(project => {
        if (project.label === 'Project') {
          return {
            label: 'Project',
            options: project.options.filter(role => role.value !== 'admin')
          };
        }
        return project;
      });
  }
  return ['project', ...apps].map(app => rolesMap[app]).filter(i => !!i);
};

export const getRoleOptionByVale = value =>
  _allRoles.find(option => option.value === value);

export const getRoleLabelByValue = value => {
  let option = _allRoles.find(option => option.value === value);
  return option && option.label;
};

export const translateRoleValuesToOptions = values =>
  _allRoles.filter(option => values.indexOf(option.value) !== -1);

export const translateRoleValuesToLabels = values =>
  translateRoleValuesToOptions(values).map(option => option.label);
