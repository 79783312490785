import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  }
});

const LoadingCircle = props => {
  const { classes, theme } = props;

  return (
    <div className={classes.root}>
      <ReactLoading
        type="bars"
        color={theme.palette.primary.main}
        height="60px"
        width="60px"
      />
    </div>
  );
};

LoadingCircle.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(LoadingCircle);
