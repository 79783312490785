import React from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { apps, hubManual } from 'config/project-apps';

import { withStyles, Button } from '@material-ui/core';

const styles = theme => ({
  button: {
    color: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.primary.contrastText}`
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none'
    }
  }
});

const ManualLink = ({ classes, location: { pathname } }) => {
  const appName = pathname.split('/')[4] || 'hub';

  const manualLink =
    appName === 'hub'
      ? hubManual
      : apps.find(obj => obj.value === appName).manualLink;

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={manualLink}
      className={classes.link}
    >
      <Button size="small" className={classes.button}>
        マニュアル
        <OpenInNewIcon className={classes.buttonRightIcon} />
      </Button>
    </a>
  );
};

export default compose(withRouter, withStyles(styles))(ManualLink);
