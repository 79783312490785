import React, { lazy, Suspense } from 'react';
import { AuthConsumer } from './AuthProvider';
import { Route } from 'react-router-dom';

import LoadingScreen from 'components/LoadingScreen';
import LoadingCircle from 'components/LoadingCircle';
import RedirectToRoot from 'components/RedirectToRoot';

export const ProtectedRoute = ({
  component: Component,
  emailVerified = true,
  ...rest
}) => (
  <AuthConsumer>
    {({
      currentUser,
      isLoading: authIsLoading,
      sendVerificationEmail,
      ...context
    }) =>
      authIsLoading ? (
        <LoadingScreen />
      ) : currentUser && (currentUser.email_verified || !emailVerified) ? (
        <Route
          render={props => (
            <Component currentUser={currentUser} {...context} {...props} />
          )}
          {...rest}
        />
      ) : (
        <RedirectToRoot />
      )
    }
  </AuthConsumer>
);

export const LazyProtectedRoute = ({ page, emailVerified = true, ...rest }) => {
  const Component = lazy(() => import(`pages/${page}`));

  const LazyComponent = props => (
    <Suspense fallback={<LoadingCircle />}>
      <Component {...props} />
    </Suspense>
  );

  return (
    <AuthConsumer>
      {({ currentUser, isLoading: authIsLoading, ...context }) =>
        authIsLoading ? (
          <LoadingScreen />
        ) : currentUser && (currentUser.email_verified || !emailVerified) ? (
          <Route
            render={props => (
              <LazyComponent
                currentUser={currentUser}
                {...context}
                {...props}
              />
            )}
            {...rest}
          />
        ) : (
          <RedirectToRoot />
        )
      }
    </AuthConsumer>
  );
};
